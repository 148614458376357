import { SimpleFlag } from "../types/Types";
import { msalToken } from "./Auth";
import { validToken } from "./Helpers";

const baseUrl = process.env.REACT_APP_API_URL;
const breakDateEtl = new Date("2024-03-21");
const FetchResult = (experimentId: any, segment: any, startDate: any, type: string, callback: any) => {  
  let searchParams = new URLSearchParams();
  if (segment) {    
    const segmentString = JSON.stringify(segment);    
    searchParams.append("segment", segmentString);    
  } 
  const expDate = new Date( parseInt(startDate) );
  if (expDate < breakDateEtl ) {    
    searchParams.append("oldetl", "true");
  }
  searchParams.append("type", type || 'ab');          
  callApi("GET", "/v1/result/" + experimentId + '?' + searchParams.toString(), callback);
};

const FetchTimeseries = (
  experimentId: number,
  metricIndex: number,
  startDate: any,
  callback: any
) => {
  const expDate = new Date( parseInt(startDate) );
    let oldEtl = expDate < breakDateEtl ? '?oldetl=true' : '';   
  callApi(
    "GET",
    "/v1/result/timeseries/" + experimentId + "/" + metricIndex+oldEtl,
    callback
  );
};

const FetchFlags = (
  callback: Function,
  page: number = 1,
  pageSize: number = 25,
  searchQuery: string = "",
  team: number,
  hasLiveRules: boolean,
  brand: number,
  archived: boolean
  // state: number,
  // region: string = "",
  // startTime: string = "",
  // endTime: string = ""
) => {
  const filter: { name: string; value: any; match: string }[] = [];
  if (team !== 0) {
    filter.push({ name: "team_id", value: team, match: "eq" });
  }

  if (brand !== 0) {
    filter.push({ name: "brand_id", value: brand, match: "eq" });
  }

  if (hasLiveRules) {    
    filter.push({
      name: "rules_in_prod",
      value: 0,
      match: "hg",
    });
  }

  const queryObject = {
    search: { word: searchQuery, where: ["name", "description", "flag_key"] },
    filter: filter.concat([{ name: "archived", value: archived, match: "eq" }]),
    /* { name: "archived", value: false, match: "eq" },
      {
        name: "created_at",
        value: ["2023-03-09", "2023-04-11"],
        match: "between",
      },
      { name: "state", value: 30, match: "eq" },*/

    sort: ["updated_at", "DESC"],
    page: page,
    page_size: pageSize,
  };

  const query = encodeURIComponent(JSON.stringify(queryObject));  

  callApi("GET", `/v1/flags?query=${query}`, callback);
};

const FetchFlag = (callback: any, flagID: number) => {
  callApi("GET", "/v1/flags/" + flagID, callback);
};

const UpdateFlagState = (callback: any, flagID: number, data: any) => {
  callApi("POST", `/v1/flags/${flagID}/state`, callback, data);
};

const UpdateFlag = (callback: any, flagID: number, data: any) => {
  callApi("POST", "/v1/flags/" + flagID, callback, data);
};

const UpdateFlagVariables = (callback: any, flagID: number, data: any) => {
  callApi("POST", `/v1/flags/${flagID}/variables`, callback, data);
};

const ArchiveFlag = (callback: any, flagId: number, data: any) => {
  callApi("POST", `/v1/flags/${flagId}/archive`, callback, data);
};

const CreateFlag = (callback: any, data: SimpleFlag) => {
  callApi("POST", "/v1/flags", callback, data);
};

const FetchRules = (callback: any, flagID: number, region: string = "", archived: boolean) => {
  let pathString = `/v1/flags/${flagID}/rules`;
  let searchParams = new URLSearchParams();
  if (region.length > 0) {        
    searchParams.append("region", region);    
  } 
  if (archived) {        
    searchParams.append("archived", "true");    
  } 
  
  if (searchParams.toString().length > 0) {
    pathString += "?" + searchParams.toString();
  }
  callApi("GET", pathString, callback);
};

const FetchRule = (callback: any, ruleId: number) => {
  callApi("GET", "/v1/rules/" + ruleId, callback);
};

const CreateRule = (callback: any, data: any) => {
  callApi("POST", "/v1/rules/", callback, data);
};

const UpdateRule = (callback: any, ruleId: number, data: any) => {
  callApi("POST", "/v1/rules/" + ruleId, callback, data);
};

const ArchiveRule = (callback: any, ruleId: number, data: any) => {
  callApi("POST", `/v1/rules/${ruleId}/archive`, callback, data);
};

const DuplicateRule = (callback: any, ruleId: number) => {
  callApi("POST", `/v1/rules/${ruleId}/duplicate`, callback);
};

const DuplicateToRollout = (callback: any, ruleId: number, variId: number) => {
  callApi("POST", `/v1/rules/${ruleId}/duplicate?rollout=true&variation=${variId}`, callback);
};

const UpdateRuleState = (callback: any, ruleId: number, data: any) => {
  callApi("POST", `/v1/rules/${ruleId}/state`, callback, data);
};

const FetchConfiguration = (callback: any) => {
  callApi("GET", "/v1/configuration", callback);
};

const FetchMetrics = (callback: any) => {
  callApi("GET", "/v1/metrics", callback);
};

const FetchAttributes = (callback: any) => {
  callApi("GET", "/v1/attributes", callback);
};

const FetchLocales = (callback: any) => {
  callApi("GET", "/v1/locales", callback);
};

const FetchAudiences = (callback: any) => {
  callApi("GET", "/v1/audiences", callback);
};

const CreateAudience = (callback: any, data: any) => {
  callApi("POST", "/v1/audiences", callback, data);
};

const UpdatePriorities = (callback: any, flagID: number, data: any) => {
  callApi("POST", `/v1/flags/${flagID}/priorities`, callback, data);
};

const FetchStatsLive = (callback: any) => {
  callApi("GET", "/v1/statistics/live", callback);
};

const FetchStatsRecent = (callback: any) => {
  callApi("GET", "/v1/statistics/recent", callback);
};

const FetchStatsLaunched = (callback: any) => {
  callApi("GET", "/v1/statistics/launched", callback);
};

const FetchStatsLaunchedByTeam = (callback: any) => {
  callApi("GET", "/v1/statistics/launchedbyteam", callback);
};

const FetchStatsLaunchedByBrand = (callback: any) => {
  callApi("GET", "/v1/statistics/launchedbybrand", callback);
};

const FetchStatsLaunchedByRegion = (callback: any) => {
  callApi("GET", "/v1/statistics/launchedbyregion", callback);
};

const FetchStatsLaunchedByPage = (callback: any) => {
  callApi("GET", "/v1/statistics/launchedbypage", callback);
};

const FetchStatsLaunchedByTouchpoint = (callback: any) => {
  callApi("GET", "/v1/statistics/launchedbytouchpoint", callback);
};

const UpdateSchedule = (callback: any, ruleId: number, data: any) => {
  callApi("POST", `/v1/schedules/${ruleId}`, callback, data);
};

const FetchCms = (callback: any) => {
  callApi("GET", "/v1/cms", callback);
};

const FetchCmsAdmin = (callback: any) => {
  callApi("GET", "/v1/admin/cms", callback);
};

const FetchHistory = (callback: any, filter: any) => {    
  const query = encodeURIComponent(JSON.stringify(filter));
  callApi("GET", `/v1/admin/history?filter=${query}`, callback);
};
const UpdateCmsKey = (callback: any, cmsId: number, data: any) => {
  callApi("POST", `/v1/admin/cms/${cmsId}`, callback, data);
};

const FetchMetricSet = (callback: any, teamId: number, regionId: number) => {
  callApi("GET", `/v1/metricset/${teamId}/${regionId}`, callback);
};

const UpdateMetricSet = (callback: any, data: any) => {
  callApi("POST", `/v1/metricset`, callback, data);
};

const FetchBucketedUsers = (callback: any, experimentId: number, startDate: any, segment: any) => {
  let searchParams = new URLSearchParams();
  if (segment) {    
    const segmentString = JSON.stringify(segment);    
    searchParams.append("segment", segmentString);    
  }
  const expDate = new Date( parseInt(startDate) );
  if (expDate > breakDateEtl ) {
      callApi("GET", "/v1/result/users/" + experimentId + '?' + searchParams.toString(), callback);    
  } else {
    callback({ status: 204, response: [], message: "No data" });
  }     
}

const FetchExperiments = (
  callback: Function,
  page: number = 1,
  pageSize: number = 25,
  searchQuery: string = "",
  team: number,
  state: number,
  region: string = "0",
  liveDateRange: any,
  brand: number
) => {
  const filter: { name: string; value: any; match: string }[] = [];

  if (brand !== 0) {
    filter.push({ name: "brand_id", value: brand, match: "eq" });
  }

  if (team !== 0) {
    filter.push({ name: "team_id", value: team, match: "eq" });
  }

  if (team === 1 && region !== "0") {
    filter.push({ name: "region_key", value: region, match: "eq" });
  }

  if (state === -1) {
    filter.push({ name: "state", value: 30, match: "ge" });
  } else {
    filter.push({ name: "state", value: state, match: "eq" });
  }

  const queryObject = {
    search: { word: searchQuery, where: ["name", "description"] },
    filter: filter.concat([{ name: "archived", value: false, match: "eq" }]),
    sort: ["updated_at", "DESC"],
    page: page,
    page_size: pageSize,
    live_date_range: liveDateRange,
  };

  const query = encodeURIComponent(JSON.stringify(queryObject));
  callApi("GET", `/v1/experiments?query=${query}`, callback);
};

const FetchTimeline = (callback: any, filter: any ) => {
  
  const query = encodeURIComponent(JSON.stringify(filter));  
  callApi("GET", `/v1/watchtower/timeline?filter=${query}`, callback);
};

const FetchClashes = (callback: any, id: number ) => {    
  callApi("GET", `/v1/watchtower/getclashes/${id}`, callback);
};

const FetchLiveClashes = (callback: any, id: number ) => {    
  callApi("GET", `/v1/watchtower/getliveclashes/${id}`, callback);
};


function callApi(type: string, url: string, callback: any, data?: any) {
  const callUrl = baseUrl + url;
  msalToken((token: string) => {
    if (validToken(token)) {
      const httpHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Apigateway-Api-Userinfo": token,
      };
      const headers = new Headers(httpHeaders);
      let status: number, message: string;
      fetch(callUrl, {
        method: type,
        headers: headers,
        redirect: "follow",
        body: JSON.stringify(data),
      })
        .then((response) => {
          status = response.status;
          message = response.statusText;
          if (response.status === 401) {
            throw Error("token_expired");
          } else if (
            !response.ok &&
            response.status !== 500 &&
            response.status !== 409
          ) {
            const error = status + ": " + message;
            throw Error(error);
          } else {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json();
            } else {
              return response.text();
            }
          }
        })
        .then((result) => {
          callback({ status: status, response: result, message: message });
        })
        .catch((error) => {
          if (error.message === "Failed to fetch") {
            //sessionStorage.setItem("fabulousTokenExpired",JSON.stringify(true));
          } else {
            callback({ status: status, response: [], message: error.message });
          }
        });
    } 
  });
}

export {
  FetchFlags,
  FetchFlag,
  UpdateFlagState,
  UpdateFlag,
  CreateFlag,
  UpdateFlagVariables,
  FetchRules,
  FetchRule,
  CreateRule,
  UpdateRule,
  ArchiveRule,
  DuplicateRule,
  UpdateRuleState,
  FetchConfiguration,
  FetchExperiments,
  FetchMetrics,
  FetchAttributes,
  UpdatePriorities,
  ArchiveFlag,
  FetchAudiences,
  CreateAudience,
  FetchStatsLive,
  FetchLocales,
  UpdateSchedule,
  FetchStatsRecent,
  FetchResult,
  FetchTimeseries,
  FetchCms,
  FetchCmsAdmin,
  UpdateCmsKey,
  FetchHistory,
  FetchMetricSet,
  UpdateMetricSet,
  FetchStatsLaunched,
  FetchStatsLaunchedByTeam,
  FetchStatsLaunchedByBrand,
  FetchStatsLaunchedByRegion,
  FetchStatsLaunchedByPage,
  FetchStatsLaunchedByTouchpoint,
  FetchBucketedUsers,
  FetchTimeline,
  FetchClashes,
  FetchLiveClashes,
  DuplicateToRollout
};
