const baseSiteWideBanner = {
  dropDownId: "site-wide-banner-dropdown",
  endDate: "2100-09-12T07:18:00Z",
  labels: {
    headline: "Headline",
    legalText: "Leagel",
    expandedCta: "Close this",
    collapsedCta: "Shop now",
  },
  launchDate: "2024-09-12T07:18:00Z",
  legalTextPlacement: "expanded",
  links: [
    {
      aliasPath: "/it_it/donna.html",
      path: "/it_it/ladies.html",
      targetPath: "/content/hmonline/it_it/ladies",
      targetTemplate: "/apps/hm/templates/content/department",
      title: "Donna",
    },
    {
      aliasPath: "/it_it/uomo.html",
      path: "/it_it/men.html",
      targetPath: "/content/hmonline/it_it/men",
      targetTemplate: "/apps/hm/templates/content/department",
      title: "Uomo",
    },
  ],
  palette: "inverted",
  shouldShowCta: true,
  triggerId: "site-wide-banner-trigger",
};

export default baseSiteWideBanner;
